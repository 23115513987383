import React, { useState, useEffect } from 'react';
import {
    Alert,
    Box,
    Button,
    CircularProgress,
    TextField,
    Typography,
} from '@mui/material';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import { useUser } from '../components/userContext';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

const InfektionenList = () => {
    const [infektionen, setInfektionen] = useState([]);
    const [isEditMode, setIsEditMode] = useState(false);
    const [editedInfektion, setEditedInfektion] = useState('');
    const { user } = useUser();
    const [isLoading, setIsLoading] = useState(true);
    const [newInfektion, setNewInfektion] = useState('');
    const [openAlert, setOpenAlert] = useState(false);
    const [serverError, setServerError] = useState(null);

    useEffect(() => {
        if (user) {
            fetchInfektionen();
        }
        // eslint-disable-next-line
    }, [user]);

    const fetchInfektionen = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URI}/api/infektionen`, {
                method: 'GET',
                headers: {
                    'Authorization': user.api_key,
                },
            });
            if (response.ok) {
                const data = await response.json();
                // Sortierung alphabetisch nach der Spalte 'infektion'
                data.sort((a, b) => a.infektion.localeCompare(b.infektion));

                setInfektionen(data);
                setServerError(null);
            }
        } catch (error) {
            console.error('Fehler beim Abrufen der Infektionen:', error.message);
            setServerError('Fehler beim Abrufen der Infektionen.');
        } finally {
            setIsLoading(false);
        }
    };

    const handleAddNewInfektion = async () => {
        try {

            if (!newInfektion) {
                // Wenn das Feld leer ist, keine Aktion ausführen
                setServerError('Fehler: Das Feld darf nicht leer sein.');
                return;
            }


            const response = await fetch(`${process.env.REACT_APP_API_URI}/api/infektionen`, {
                method: 'POST',
                headers: {
                    'Authorization': user.api_key,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ infektion: newInfektion }),
            });

            if (response.ok) {
                setNewInfektion(''); // Zurücksetzen des Eingabefelds
                fetchInfektionen(); // Aktualisieren der Infektionenliste
                setServerError(null);

                // Alert anzeigen
                setOpenAlert(true);

                // Alert nach 2 Sekunden ausblenden
                setTimeout(() => {
                    setOpenAlert(false);
                }, 2000);
            } else {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Fehler beim Hinzufügen der Infektion.');
            }
        } catch (error) {
            console.error('Fehler beim Hinzufügen der Infektion:', error.message);
            setServerError('Fehler beim Hinzufügen der Infektion.');
        }
    };

    const handleSaveEdit = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URI}/api/infektionen/${editedInfektion.infektion_id}`, {
                method: 'PUT',
                headers: {
                    'Authorization': user.api_key,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ infektion: editedInfektion.infektion }),
            });

            if (response.ok) {
                // Infektion erfolgreich aktualisiert, verlassen Sie den Edit-Modus
                setIsEditMode(false);
                // Aktualisieren Sie die Infektionsliste
                fetchInfektionen();
                setServerError(null);
            } else {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Fehler beim Aktualisieren der Infektion.');
            }
        } catch (error) {
            console.error('Fehler beim Aktualisieren der Infektion:', error.message);
        }
    };


    const handleDeleteInfektion = async (id) => {
        try {
            // Fügen Sie hier Ihren Code zum Löschen der Infektion mit der angegebenen ID hinzu
            const response = await fetch(`${process.env.REACT_APP_API_URI}/api/infektionen/${id}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': user.api_key,
                },
            });

            if (response.ok) {
                // Infektion erfolgreich gelöscht, aktualisieren Sie die Infektionenliste
                fetchInfektionen();
                setServerError(null);
            } else {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Fehler beim Löschen der Infektion.');
            }
        } catch (error) {
            console.error('Fehler beim Löschen der Infektion:', error.message);
            setServerError('Fehler beim Löschen der Infektion: ' + error.message);
        }
    };

    const enterEditMode = () => {
        setIsEditMode(true);
    };
    // Funktion zum Abbrechen des Edit-Modus
    const cancelEdit = () => {
        setIsEditMode(false);
    };

    const handleEditInfektion = (id) => {
        const infektionToEdit = findInfektionById(id);
        setEditedInfektion({ ...infektionToEdit });
        enterEditMode(); // Betreten Sie den Edit-Modus
    };

    function findInfektionById(id) {
        const infektionId = Number(id); // Konvertieren Sie die ID in eine Zahl

        // Durchsuchen Sie die Infektionsliste nach der Infektion mit der entsprechenden ID
        const foundInfektion = infektionen.find((infektion) => infektion.infektion_id === infektionId);

        return foundInfektion;
    }


    const columns = [
        { field: 'infektion_id', headerName: 'ID', width: 100 },
        { field: 'infektion', headerName: 'Infektion', width: 300 },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Aktionen',
            width: 120,
            getActions: (params) => {
                return ([
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Löschen"
                        onClick={() => handleDeleteInfektion(params.id)}
                        showInMenu
                    />,
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Bearbeiten"
                        onClick={() => handleEditInfektion(params.id)}
                    />,
                ]);
            },
        }
    ];

    return (
        <Box m={1}>
            <Typography variant="h5" color="#E46450" component="h1" gutterBottom mt={1}>
                Infektionenliste
            </Typography>
            {isLoading && (
                <CircularProgress color="inherit" />
            )}
            {!isLoading && (
                <div>
                    {isEditMode ? (
                        <Box mb={2}>
                            <TextField
                                label="Infektion bearbeiten"
                                variant="outlined"
                                fullWidth
                                value={editedInfektion.infektion}
                                onChange={(e) => setEditedInfektion({ ...editedInfektion, infektion: e.target.value })}
                                error={!editedInfektion.infektion}
                                helperText={!editedInfektion.infektion && 'Das Feld darf nicht leer sein.'}
                            />
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleSaveEdit}
                                disabled={!editedInfektion.infektion}
                                sx={{ mt: 2 }}
                            >
                                Speichern
                            </Button>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={cancelEdit}
                                sx={{ ml: 2, mt: 2 }}
                            >
                                Abbrechen
                            </Button>
                        </Box>
                    ) : (
                        <Box mb={2}>
                            <TextField
                                label="Neue Infektion hinzufügen"
                                variant="outlined"
                                size="small"
                                value={newInfektion}
                                onChange={(e) => setNewInfektion(e.target.value)}
                            />
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<AddIcon />}
                                onClick={handleAddNewInfektion}
                                sx={{ ml: 2 }}
                            >
                                Hinzufügen
                            </Button>
                        </Box>
                    )}

                    {serverError && <Alert severity="error">{serverError}</Alert>}
                    {openAlert && (
                        <Alert severity="success" sx={{ mt: 2 }}>
                            Infektion erfolgreich hinzugefügt.
                        </Alert>
                    )}
                    <div style={{ width: '100%' }}>
                        <DataGrid
                            rows={infektionen}
                            columns={columns}
                            pageSize={5}
                            getRowId={(row) => row.infektion_id} />
                    </div>

                </div>
            )}
        </Box>
    );
};

export default InfektionenList;
