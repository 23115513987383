import React, { useState } from 'react';
import {
    Button,
    TextField,
    Container,
    Typography,
    Alert,
    Box,
} from '@mui/material';
import { useUser } from '../components/userContext';
import { useNavigate } from 'react-router-dom';
import { isPasswordValid } from '../misc/helperFunctions';

const ChangePasswordPage = () => {
    const { user } = useUser();
    const [formData, setFormData] = useState({
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
    });
    const [errors, setErrors] = useState({});
    const [serverError, setServerError] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isPasswordChanged, setIsPasswordChanged] = useState(false);
    const navigate = useNavigate();

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        // Validate form fields
        const newErrors = {};
        if (!formData.currentPassword) {
            newErrors.currentPassword = 'Altes Passwort erforderlich';
        }
        if (!formData.newPassword) {
            newErrors.newPassword = 'Neues Passwort erforderlich';
        }
        if (!isPasswordValid(formData.newPassword)) {
            newErrors.newPassword = 'Passwort muss mindestens 6 Zeichen lang sein, einen Großbuchstaben und ein Sonderzeichen enthalten';
        }
        if (!formData.confirmPassword) {
            newErrors.confirmPassword = 'Passwortbestätigung erforderlich';
        }
        if (formData.newPassword !== formData.confirmPassword) {
            newErrors.confirmPassword = 'Passwörter stimmen nicht überein';
        }
        setErrors(newErrors);

        if (Object.keys(newErrors).length === 0) {
            setIsSubmitting(true);

            try {

                console.log(user);
                console.log(user.user_id);

                const response = await fetch(
                    `${process.env.REACT_APP_API_URI}/api/users/${user.user_id}/password`,
                    {
                        method: 'PUT',
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: user.api_key,
                        },
                        body: JSON.stringify({
                            currentPassword: formData.currentPassword,
                            newPassword: formData.newPassword,
                        }),
                    }
                );

                if (response.ok) {
                    console.log('Password updated successfully');
                    setServerError('');
                    setIsPasswordChanged(true);
                    setTimeout(() => {
                        navigate(-1); // Navigate after a short delay
                    }, 2000); // Delay of 2 seconds
                } else {
                    const errorData = await response.json();
                    throw new Error(errorData.error);
                }
            } catch (error) {
                console.error('Error updating password:', error.message);
                setServerError('Fehler beim Aktualisieren des Passworts: ' + error.message);
            }

            setIsSubmitting(false);
        }
    };

    const handleCancel = () => {
        navigate(-1);
    };

    return (
        <Container maxWidth="xs" sx={{ mt: 1 }}>
            <Box
                mt={4}
                p={4}
                style={{
                    background: 'linear-gradient(to bottom, #FFFFFF, #F5F5F5)',
                    borderRadius: '10px',
                    border: '1px solid #F5F5F5',
                }}
            >
                <Typography variant="h5" component="h1" gutterBottom textAlign="center">
                    Passwort ändern
                </Typography>
                {errors && Object.values(errors).join(', ') && (
                    <Alert severity="error" sx={{ my: 2 }}>
                        {Object.values(errors).join(', ')}
                    </Alert>
                )}
                {serverError && (
                    <Alert severity="error" sx={{ my: 2 }}>
                        {serverError}
                    </Alert>
                )}
                <Box component="form" onSubmit={handleSubmit}>
                    <TextField
                        fullWidth
                        label="Aktuelles Passwort"
                        name="currentPassword"
                        type="password"
                        value={formData.currentPassword}
                        onChange={handleChange}
                        margin="normal"
                        error={!!errors.currentPassword}
                        helperText={errors.currentPassword}
                    />
                    <TextField
                        fullWidth
                        label="Neues Passwort"
                        name="newPassword"
                        type="password"
                        value={formData.newPassword}
                        onChange={handleChange}
                        margin="normal"
                        error={!!errors.newPassword}
                        helperText={errors.newPassword}
                    />
                    <TextField
                        fullWidth
                        label="Passwort bestätigen"
                        name="confirmPassword"
                        type="password"
                        value={formData.confirmPassword}
                        onChange={handleChange}
                        margin="normal"
                        error={!!errors.confirmPassword}
                        helperText={errors.confirmPassword}
                    />
                    <Box sx={{ mt: 2 }}>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            style={{ marginTop: '16px' }}
                            disabled={isSubmitting || isPasswordChanged}
                        >
                            Passwort ändern
                        </Button>
                        <Button variant="outlined" color="secondary" style={{ marginTop: '16px', marginLeft: '12px' }} onClick={handleCancel}>
                            Abbrechen
                        </Button>
                    </Box>
                </Box>
                {isPasswordChanged && ( // Display success alert
                    <Alert severity="success" sx={{ my: 2 }}>
                        Passwort wurde erfolgreich geändert.
                    </Alert>
                )}
            </Box>
        </Container>
    );
};

export default ChangePasswordPage;
