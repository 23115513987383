import React, { useState, useEffect } from 'react';
import { Box, Stack, Typography, IconButton, CircularProgress, Alert } from '@mui/material';
import { useUser } from '../components/userContext';
import { DataGrid } from '@mui/x-data-grid';
import RefreshIcon from '@mui/icons-material/Refresh';


const LogsPage = () => {
    const { user } = useUser();
    const [logData, setLogData] = useState([]);
    const [isLoading, setIsLoading] = useState(true); // for loading Icon
    // eslint-disable-next-line
    const [serverError, setServerError] = useState(null);



    useEffect(() => {
        if (user) {
            fetchLogs();
        }
        // eslint-disable-next-line
    }, [user]);

    const fetchLogs = async () => {
        try {
            const response = await fetch(process.env.REACT_APP_API_URI + '/api/logs', {
                headers: {
                    'Authorization': user.api_key,
                },
            });
            if (!response.ok) {
                throw new Error('Fehler beim Abrufen der ');
            }
            const data = await response.json();
            setLogData(data);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const columns = [
        { field: 'id', headerName: 'Id', width: 60, },
        {
            field: 'timestamp',
            headerName: 'Zeitstempel',
            width: 160,
            valueFormatter: (params) => {
                // Erstelle ein Date-Objekt aus dem UTC-Zeitstempel
                const utcDate = new Date(params.value);

                // Konvertiere das Date-Objekt in das gewünschte deutsche Format
                const germanDate = utcDate.toLocaleString('de-DE', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                });

                return germanDate;
            },
        },
        {
            field: 'level',
            headerName: 'level',
            width: 100,
            cellClassName: (params) => {
                return params.value === 'error' ? 'error' : ''; // Weise die CSS-Klasse "error" zu, wenn level 'error' ist
            },
        },
        { field: 'message', headerName: 'Nachricht', width: 9000, },
    ];



    return (
        <Box m={1}>
            <Box display="flex" alignItems="center" justifyContent="space-between">
                <Stack justifyItems="flex-start" alignItems="center" direction="row">
                    <Typography variant="h5" color="#E46450" component="h1" gutterBottom mt={1}>
                        API Log-File (for admins only)
                    </Typography>

                    {isLoading && (
                        <CircularProgress color="inherit" />
                    )}
                    {(!isLoading) && (
                        <IconButton ml={2} size="large" aria-label="refresh" onClick={fetchLogs}>
                            <RefreshIcon fontSize="large" />
                        </IconButton>
                    )}
                </Stack>

            </Box>
            {serverError && <Alert severity="error" sx={{ my: 2 }}>{serverError}</Alert>}

            <Box display="flex" alignItems="center" justifyContent="space-between" mb={1} sx={{
                border: '1px solid #ccc', // Leicht grauer Rahmen
                borderRadius: '4px', // Runde Ecken (optional)
                padding: '14px', // Innenabstand
            }}>
                <Typography variant="body" sx={{ fontStyle: 'italic' }}>Log-File von heute der <strong>API</strong> ktw-anmeldung.de. Location: HOME_KTW/api/logs/</Typography>
            </Box>

            <div style={{ width: '100%' }}>
                <DataGrid
                    rows={logData}
                    columns={columns}
                    loading={isLoading}
                    getRowId={(row) => row.id}
                    rowHeight={40}
                />
            </div>
        </Box>
    );


};

export default LogsPage;
