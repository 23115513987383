import React, { useEffect, useState } from 'react';
import { Typography, Box, Paper, Grid, Table, TableBody, TableCell, TableContainer, TableRow, Tooltip } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { useUser } from './userContext';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

const ContentIls = () => {

    const { user } = useUser();
    const [systemInfo, setSystemInfo] = useState({});

    useEffect(() => {
        const fetchSystemInfo = async () => {
            try {
                const response = await fetch(process.env.REACT_APP_API_URI + '/api/system-info', {
                    headers: {
                        'Authorization': user.api_key,
                    },
                });
                if (!response.ok) {
                    throw new Error('Fehler beim Abrufen der Systeminformationen');
                }
                const data = await response.json();
                setSystemInfo(data);
            } catch (error) {
                console.error(error);
            }
        };

        fetchSystemInfo();
        // eslint-disable-next-line
    }, []); // Run only once when component mounts

    const backgroundImageUrl = `${process.env.REACT_APP_BASE_URL}/img_ils.jpg`;


    return (
        <Box p={2} >
            <Box
                sx={{
                    position: 'relative', // Set the position to relative
                    width: '100%', // Take up the full width
                    height: '300px', // Set the height of the image
                    backgroundImage: `url(${backgroundImageUrl})`, // Set the background image
                    backgroundSize: 'cover', // Scale the image to cover the entire box
                    backgroundPosition: 'center', // Center the image
                    marginBottom: '20px', // Add margin at the bottom
                    borderRadius: '10px',
                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', // Add a shadow
                }}
            />
            <Grid container spacing={4} columns={{ xs: 4, md: 12 }}>
                <Grid item xs={6}>
                    <Paper elevation={3} sx={{ padding: '20px', borderRadius: '10px', background: 'linear-gradient(to bottom, #FFFFFF, #F5F5F5)', minHeight: '460px' }}>
                        <Typography variant="h6" align="center" color="#E46450" sx={{ fontWeight: 'bold' }}>Willkommen bei KTW-Anmeldung.de!</Typography>
                        <Typography variant="body1" align="center" sx={{ marginTop: 2, fontStyle: 'italic' }}>
                            Sie sind als <strong>Disponent</strong> der Leitstelle angemeldet.<br /> Hier einige wichtige Informationen für Sie:
                        </Typography>
                        <Typography variant="body1" align="center" sx={{ marginTop: 2 }}>
                            Als Disponent der ILS stehen Ihnen folgende Funktionen zur Verfügung:
                        </Typography>
                        <br />
                        <Box component="ul" sx={{ paddingInlineStart: '20px', maxWidth: '80%', margin: '0 auto', listStyleType: 'disc' }}>
                            <Typography variant="body1">
                                <li>
                                    <RouterLink
                                        to="/transportlist"
                                    >
                                        <strong>Bestellübersicht:</strong>
                                    </RouterLink> Sie haben die Möglichkeit, sämtliche Bestellungen in einer tabellarischen Übersicht einzusehen, unabhängig von ihrem aktuellen Status.
                                </li>
                                <br />
                                <li>
                                    <RouterLink
                                        to="/transport"
                                    >
                                        <strong>Manuelle Bestellung:</strong>
                                    </RouterLink> Als Disponent können Sie im Auftrag von medizinischen Einrichtungen Krankentransport-Bestellungen erfassen.
                                </li>
                                <br />
                                <li>
                                    <RouterLink
                                        to={{
                                            pathname: "/transportlist",
                                            search: "?filterStatus=pending"
                                        }}
                                    >
                                        <strong>Bestellungsgenehmigung:</strong>
                                    </RouterLink> Sie sind befugt, Bestellungen, die von Benutzern oder Gästen erstellt wurden, zu prüfen, zu genehmigen oder abzulehnen.
                                </li>
                            </Typography>
                        </Box>
                    </Paper>
                </Grid>
                <Grid item xs={6}>
                    <Paper elevation={3} sx={{ padding: '20px', borderRadius: '10px', background: 'linear-gradient(to bottom, #FFFFFF, #F5F5F5)', minHeight: '460px' }}>
                        <Typography variant="h6" align="center" color="#E46450" sx={{ fontWeight: 'bold' }}>
                            Systeminformationen
                        </Typography>
                        <TableContainer>
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>
                                            <Tooltip title="Freigegebene, also geplante Transporte, die demnächst durchgeführt werden.">
                                                <Typography variant="body1">Freigegebene Transporte (aktiv):</Typography>
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body1">{systemInfo.activeTransports}</Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Typography variant="body1">
                                                <Tooltip title="Einige neue Transporte (Bestellungen) haben den Status 'pending'. Bitte neue Transporte freigeben oder ablehnen.">
                                                    <RouterLink
                                                        to={{
                                                            pathname: "/transportlist",
                                                            search: "?filterStatus=pending"
                                                        }}
                                                        style={{ textDecoration: 'none', display: 'flex', alignItems: 'center' }}
                                                    >
                                                        <strong>Transporte zur Bestätigung:</strong>
                                                        {systemInfo.pendingTransports > 0 && (
                                                            <span style={{ marginTop: '2px', marginLeft: '8px', color: 'red' }}>
                                                                <WarningAmberIcon fontSize="small" />
                                                            </span>
                                                        )}
                                                    </RouterLink>
                                                </Tooltip>
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body1">{systemInfo.pendingTransports}</Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Tooltip title="Transporte (Bestellungen), die heute mit KTW-Anmeldung angelegt wurden.">
                                                <Typography variant="body1">Heute angelegte Transporte:</Typography>
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body1">{systemInfo.todayCreatedTransports}</Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    );
}


export default ContentIls;
