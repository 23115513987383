import React from 'react';
import Content from './content';
import ContentIls from './contentIls'; // Import ContentIls component
import ContentAdmin from './contentAdmin'; // Import ContentAdmin component
import { useUser } from './userContext';

const Dashboard = () => {
    const { user } = useUser();

    const allowedRoles = ['user', 'dialysis'];

    // Je nach Benutzerrolle die entsprechende Komponente anzeigen
    if (allowedRoles.includes(user.role)) {
        return <Content />;
    } else if (user.role === 'poweruser') {
        return <ContentIls />;
    } else if (user.role === 'admin') {
        return <ContentAdmin />;
    }

    // Falls die Benutzerrolle nicht erkannt wurde, standardmäßig Content anzeigen
    return <Content />;
}

export default Dashboard;
