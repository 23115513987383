import React, { useState } from 'react';
import {
    FormControl,
    FormLabel,
    Box,
    Button,
    Alert,
    Typography,
} from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';

const LABEL_WIDTH = '270px';
const BUTTON_WIDTH = '100px';

const ButtonGroupOption = ({ label, value, selected, onSelect }) => (
    <Button
        style={{ width: BUTTON_WIDTH }}
        variant={selected === value ? 'contained' : 'outlined'}
        onClick={() => onSelect(value)}
    >
        {label}
    </Button>
);

const BooleanButtonGroup = ({ fieldName, label, options, formData, setFormData, customLabelStyle }) => {
    const [confirmMessage, setConfirmMessage] = useState(formData[`confirm_${fieldName}`]);

    const handleOptionChange = (value) => {
        if (fieldName === 'infectious' && formData.infectious && !value) {
            // Reset infektion_id when switching from 'Ja' to 'Nein'
            setFormData((prevData) => ({
                ...prevData,
                [fieldName]: value,
                infektion_id: null,
            }));
        } else {
            setFormData((prevData) => ({
                ...prevData,
                [fieldName]: value,
            }));
        }
    };

    const handleConfirmChange = () => {
        setConfirmMessage(true);
        setFormData((prevData) => ({
            ...prevData,
            [`confirm_${fieldName}`]: true,
        }));
    };

    return (
        <FormControl component="fieldset" margin="normal" fullWidth>
            <Box display="flex" mt={2}>
                <FormLabel
                    component="legend"
                    style={{
                        marginTop: '6px',
                        width: LABEL_WIDTH,
                        marginRight: '20px',
                        color: formData.hasOwnProperty(fieldName) ? 'inherit' : '#E61F02',
                        ...customLabelStyle,
                    }}
                >
                    {label} {formData.hasOwnProperty(fieldName) ? '' : '*'}
                </FormLabel>
                <Box ml={2}>
                    {options.map((option) => (
                        <ButtonGroupOption
                            key={option.value}
                            label={option.label}
                            value={option.value}
                            selected={formData[fieldName]}
                            onSelect={handleOptionChange}
                        />
                    ))}
                    {fieldName === 'luggage' && formData.luggage === true && !confirmMessage && (
                        <Box mt={2}>
                            <Alert severity="warning" variant="outlined">
                                Es kann maximal nur ein Gepäckstück<br /> mitgenommen werden!<br />
                                <Button
                                    variant="outlined"
                                    size="small"
                                    onClick={handleConfirmChange}
                                    style={{ marginTop: '4px' }}
                                >
                                    Ok, das habe ich verstanden
                                </Button>
                            </Alert>
                        </Box>
                    )}
                    {fieldName === 'luggage' && formData.luggage === true && confirmMessage && (
                        <Box
                            mt={1} // Optional: Innenabstand anpassen
                            display="flex"
                            alignItems="center"
                        >
                            <WarningIcon color="warning" fontSize="small" />
                            <Typography ml={1} variant="body2">Maximal nur ein Gepäckstück.</Typography>
                        </Box>
                    )}
                    {fieldName === 'companion' && formData.companion === true && !confirmMessage && (
                        <Box mt={2}>
                            <Alert severity="warning" variant="outlined">
                                Maximal eine Begleitperson!<br />
                                <Button
                                    variant="outlined"
                                    size="small"
                                    onClick={handleConfirmChange}
                                    style={{ marginTop: '4px' }}
                                >
                                    Ok, das habe ich verstanden
                                </Button>
                            </Alert>
                        </Box>
                    )}
                    {fieldName === 'companion' && formData.companion === true && confirmMessage && (
                        <Box
                            mt={1}
                            display="flex"
                            alignItems="center"
                        >
                            <WarningIcon color="warning" fontSize="small" />
                            <Typography ml={1} variant="body2">Maximal eine Begleitperson.</Typography>
                        </Box>
                    )}
                    {fieldName === 'prescription_present' && formData.prescription_present === false && !confirmMessage && (
                        <Box mt={2}>
                            <Alert severity="warning" variant="outlined">
                                Bei Abholung muss die Verordnung übergeben werden!<br />
                                <Button
                                    variant="outlined"
                                    size="small"
                                    onClick={handleConfirmChange}
                                    style={{ marginTop: '4px' }}
                                >
                                    Ok, das habe ich verstanden
                                </Button>
                            </Alert>
                        </Box>
                    )}
                    {fieldName === 'prescription_present' && formData.prescription_present === false && confirmMessage && (
                        <Box
                            mt={1}
                            display="flex"
                            alignItems="center"
                        >
                            <WarningIcon color="warning" fontSize="small" />
                            <Typography ml={1} variant="body2">Bei Abholung muss die<br /> Verordnung übergeben werden!</Typography>
                        </Box>
                    )}
                    {fieldName === 'folding' && formData.own_wheelchair === true && formData.folding === false && !confirmMessage && (
                        <Box mt={2}>
                            <Alert severity="warning" variant="outlined">
                                Der Rollstuhl kann nicht mitgenommen werden!<br />
                                <Button
                                    variant="outlined"
                                    size="small"
                                    onClick={handleConfirmChange}
                                    style={{ marginTop: '4px' }}
                                >
                                    Ok, das habe ich verstanden
                                </Button>
                            </Alert>
                        </Box>
                    )}
                    {fieldName === 'folding' && formData.own_wheelchair === true && formData.folding === false && confirmMessage && (
                        <Box
                            mt={1}
                            display="flex"
                            alignItems="center"
                        >
                            <DoDisturbIcon color="error" fontSize="small" />
                            <Typography ml={1} variant="body2">Der Rollstuhl kann nicht mitgenommen werden!</Typography>
                        </Box>
                    )}
                    {fieldName === 'insurance_approved' && formData.transport_mode === 'sitzend' && formData.insurance_approved === false && !confirmMessage && (
                        <Box mt={2}>
                            <Alert severity="warning" variant="outlined">
                                Möglicherweise fallen für diesen Transport Privatkosten an.<br />
                                Mir ist bewusst, dass ich im Falle einer Ablehnung<br /> durch die Krankenkasse die Kosten des Transports<br /> privat zu tragen habe.<br />
                                <Button
                                    variant="outlined"
                                    size="small"
                                    onClick={handleConfirmChange}
                                    style={{ marginTop: '4px' }}
                                >
                                    Ok, das habe ich verstanden
                                </Button>
                            </Alert>
                        </Box>
                    )}
                    {fieldName === 'insurance_approved' && formData.transport_mode === 'sitzend' && formData.insurance_approved === false && confirmMessage && (
                        <Box
                            mt={1}
                            display="flex"
                            alignItems="center"
                        >
                            <WarningIcon color="warning" fontSize="small" />
                            <Typography ml={1} variant="body2">
                                Mir ist bewusst, dass ich im Falle einer Ablehnung<br /> durch die Krankenkasse die Kosten des Transports<br />  privat zu tragen habe.
                            </Typography>
                        </Box>
                    )}
                </Box>
            </Box>
        </FormControl>
    );
};

export default BooleanButtonGroup;