import React, { useState, useEffect } from 'react';
import { Box, Container, Link, Stack, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';

const ConfirmationPage = () => {
    const { token } = useParams(); // Entnimmt den Wert des 'token' Parameters aus der URL
    const [confirmationStatus, setConfirmationStatus] = useState('pending'); // Zustandsvariable für den Status

    const updateUser = async (updatedUser) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URI}/api/users/${updatedUser.user_id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': updatedUser.api_key,
                },
                body: JSON.stringify(updatedUser),
            });

            if (response.ok) {
                setConfirmationStatus('confirmed');
            } else {
                console.error('Fehler bei der API-Anfrage:', response.statusText);
            }
        } catch (error) {
            console.error('Fehler bei der API-Anfrage:', error);
        }
    };

    useEffect(() => {
        // Extrahiere den token Parameter aus der URL
        const urlSearchParams = new URLSearchParams(window.location.search);
        const token = urlSearchParams.get('token');

        // Überprüfe, ob der token Parameter vorhanden ist
        if (token) {
            fetch(`${process.env.REACT_APP_API_URI}/api/users/token/${token}`)
                .then(response => response.json())
                .then(data => {
                    if (data && data.user_id) {
                        const updatedUser = { ...data, registration_status: 'bestätigt', reg_token: null };
                        updateUser(updatedUser);
                    }
                })
                .catch(error => {
                    console.error('Fehler bei der API-Anfrage:', error);
                });
        } else {
            console.error('Kein Token in der URL gefunden.');
        }
    }, [token]);

    return (
        <Container maxWidth="sm" sx={{ mt: 1 }}>
            <Box
                mt={4}
                p={4}
                style={{
                    background: 'linear-gradient(to bottom, #FFFFFF, #F5F5F5)',
                    borderRadius: '10px',
                    border: '1px solid #F5F5F5',
                }}
            >
                <Typography variant="h5" component="h1" gutterBottom textAlign="center">
                    Registrierung erfolgreich!
                </Typography>
                {confirmationStatus === 'pending' ? (
                    <Typography variant="body1" textAlign="center" gutterBottom>
                        Vielen Dank für Ihre Registrierung.<br />
                        Sie erhalten in Kürze eine Bestätigungs-Mail.
                    </Typography>
                ) : (
                    <Typography variant="body1" gutterBottom>
                        Vielen Dank. Ihre E-Mail wurde bestätigt! Die Leitstelle wird ihren Zugang in Kürze freischalten.
                    </Typography>
                )}
                <Stack direction="row" sx={{ mt: 4 }} alignItems="center" justifyContent="center">
                    <Link ml={1} variant="body2" href="/login">Zurück zur Anmeldung</Link>
                </Stack>
            </Box>
        </Container>
    );
};

export default ConfirmationPage;
