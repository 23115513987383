import React, { useState } from 'react';
import {
    Alert,
    Box,
    Button,
    Dialog,
    IconButton,
    Stack,
    Typography,
} from '@mui/material';
import BooleanButtonGroup from '../components/buttonGroup';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';


const TransportDetailsStep = ({ formData, setFormData }) => {

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    return (
        <Box mt={4} mb={2} pt={12} pl={4} pr={4} pb={4} style={{
            background: 'linear-gradient(to bottom, #FFFFFF, #F5F5F5)', borderRadius: '10px', border: '1px solid #F5F5F5', position: 'relative', zIndex: 1,
        }}>
            <Typography variant="h6" mb={2}>Details zum Transport</Typography>
            <BooleanButtonGroup
                fieldName="transport_mode"
                label="Transportart"
                options={[
                    { label: 'Sitzend', value: 'sitzend' },
                    { label: 'Liegend', value: 'liegend' },
                ]}
                formData={formData}
                setFormData={setFormData}
            />
            {formData.transport_mode === 'sitzend' && (
                <BooleanButtonGroup
                    fieldName="insurance_approved"
                    label="Wurde der Transport durch die Krankenkasse genehmigt?"
                    options={[
                        { label: 'Ja', value: true },
                        { label: 'Nein', value: false },
                    ]}
                    formData={formData}
                    setFormData={setFormData}
                />
            )}
            <BooleanButtonGroup
                fieldName="own_wheelchair"
                label="Eigener Rollstuhl vorhanden?"
                options={[
                    { label: 'Ja', value: true },
                    { label: 'Nein', value: false },
                ]}
                formData={formData}
                setFormData={setFormData}
            />

            {formData.own_wheelchair === true && (
                <BooleanButtonGroup
                    fieldName="folding"
                    label="Ist der Rollstuhl klappbar?"
                    options={[
                        { label: 'Ja', value: true },
                        { label: 'Nein', value: false },
                    ]}
                    formData={formData}
                    setFormData={setFormData}
                />
            )}

            <BooleanButtonGroup
                fieldName="luggage"
                label="Gepäck vorhanden?"
                options={[
                    { label: 'Ja', value: true },
                    { label: 'Nein', value: false },
                ]}
                formData={formData}
                setFormData={setFormData}
            />

            <BooleanButtonGroup
                fieldName="companion"
                label="Begleitperson?"
                options={[
                    { label: 'Ja', value: true },
                    { label: 'Nein', value: false },
                ]}
                formData={formData}
                setFormData={setFormData}
            />

            <BooleanButtonGroup
                fieldName="oxygen_required"
                label="Patient Sauerstoffpflichtig"
                options={[
                    { label: 'Ja', value: true },
                    { label: 'Nein', value: false },
                ]}
                formData={formData}
                setFormData={setFormData}
            />

            {formData.oxygen_required === true && (
                <BooleanButtonGroup
                    fieldName="oxygen_source"
                    label="Sauerstoff ab:"
                    options={[
                        { label: 'Patient', value: 'ab_patient' },
                        { label: 'Fahrzeug', value: 'ab_fahrzeug' },
                    ]}
                    formData={formData}
                    setFormData={setFormData}
                />
            )}

            <BooleanButtonGroup
                fieldName="prescription_present"
                label="Liegt eine gültige Verordnung vor?"
                options={[
                    { label: 'Ja', value: true },
                    { label: 'Nein', value: false },
                ]}
                formData={formData}
                setFormData={setFormData}
            />
            <Stack direction="row" sx={{ mt: 0 }} >
                <Box style={{
                    width: '300px',
                }}></Box>
                <Button
                    variant="text"
                    onClick={handleClickOpen}
                    startIcon={<InfoIcon />}
                    sx={{
                        fontStyle: 'italic',
                        fontSize: '0.8rem'
                    }}
                >
                    Was ist eine gültige Verordnung?
                </Button>
            </Stack>
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="lg"
            >
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <Alert severity="info" sx={{ margin: 2, fontSize: '1.2em', }}>
                    In dem offiziellen Formular für die <strong>Verordnung einer Krankenbeförderung</strong> ist es wichtig, dass das Kästchen neben 'KTW' (Krankentransportwagen) angekreuzt ist. Dies bestätigt, dass der Patient einen Krankentransportwagen für die Beförderung benötigt. <strong>Siehe weiter unten im Abschnitt "Art und Ausstattung der Beförderung"</strong>.
                </Alert>
                <img
                    src="/Muster_Trapo.jpg"
                    alt="Verordnung"
                    style={{ width: '100%' }}
                />
            </Dialog>
        </Box>
    );
};

export default TransportDetailsStep;
