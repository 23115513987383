
import { Navigate } from "react-router-dom";
import { useUser } from './userContext'
import jwtDecode from 'jwt-decode';

function PrivateRoute({ children, ...rest }) {
    const { user, logout } = useUser();
    const token = localStorage.getItem('token');

    let isTokenExpired = true;
    if (token) {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000;
        if (decodedToken.exp > currentTime) {
            isTokenExpired = false;
        }
    }

    if (!user || isTokenExpired) {
        logout();
        return <Navigate to="/login" replace />;
    }

    const { role } = user;
    if (rest.adminOnly && role !== 'admin') {
        return <Navigate to="/dashboard" replace />;
    }

    return children;
}


export { PrivateRoute };
