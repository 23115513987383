import React, { Component } from 'react';
import { Button, Container, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const sendErrorToServer = (error, errorInfo) => {
    const errorData = {
        error: error.toString(),
        errorInfo: errorInfo.componentStack,
    };

    fetch(`${process.env.REACT_APP_API_URI}/api/logError`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(errorData),
    })
        .then(response => {
            if (!response.ok) {
                console.error('Fehler beim Senden des Fehlers an den Server:', response.status);
            }
        })
        .catch(err => {
            console.error('Fehler beim Senden des Fehlers an den Server:', err);
        });
};

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, error: null };
    }

    static getDerivedStateFromError(error) {
        console.error("ErrorBoundary getDerivedStateFromError:", error);
        return { hasError: true, error: error };
    }

    componentDidCatch(error, errorInfo) {
        console.log("ErrorBoundary componentDidCatch:");
        sendErrorToServer(error, errorInfo);

        // Überprüfen Sie, ob es der spezifische Fehler ist
        if (error.message.includes("The node to be removed is not a child of this node")) {
            // Loggen Sie den Fehler (optional)
            console.error("Spezifischer Fehler abgefangen:", error);

            // Ignorieren Sie den Fehler (nicht empfohlen)
            this.setState({ hasError: false });
        }
    }

    render() {
        const NavigateButton = () => {
            const navigate = useNavigate();
            return (
                <Button variant="outlined" onClick={() => navigate('/dashboard')}>
                    Zurück zur Startseite
                </Button>
            );
        };

        if (this.state.hasError) {
            return (
                <Container maxWidth="md" sx={{ mt: 2 }}>
                    <Typography variant="h5" component="h1" gutterBottom>
                        Oops!<br />
                    </Typography>
                    <Typography variant="p" component="p" gutterBottom>
                        Sorry, ein unerwarteter Fehler ist aufgetreten:
                    </Typography>
                    <Typography variant="p" component="p" gutterBottom>
                        <i>{this.state.error.message}</i>
                    </Typography>
                    <NavigateButton />
                </Container>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
