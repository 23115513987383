import React, { useState, useEffect } from 'react';
import { Typography, Button, Alert, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Box, TextField, InputAdornment, IconButton, CircularProgress, Stack, FormControlLabel, Checkbox } from '@mui/material';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { useUser } from '../components/userContext';
import { useNavigate, useLocation } from 'react-router-dom';
// Import other necessary dependencies and components
import PauseIcon from '@mui/icons-material/Pause';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';
import RefreshIcon from '@mui/icons-material/Refresh';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import VaccinesIcon from '@mui/icons-material/Vaccines';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'; // import plugin
import { renewToken } from '../misc/helperFunctions';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
dayjs.extend(utc); // use plugin

const ConfirmationDialog = ({ open, onClose, onConfirm, title, description }) => {
    const [reason, setReason] = useState(''); // Storno-Grund als State-Variable

    const handleReasonChange = (event) => {
        setReason(event.target.value);
    };

    const handleConfirm = () => {
        onConfirm(true, reason); // Übergibt den Storno-Grund an handleConfirmation
        setReason(''); // Clear reason after confirmation
    };

    const isStorno = title === 'Stornieren';

    return (
        <Dialog open={open} onClose={() => onClose(false)}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText>{description}</DialogContentText>
                {isStorno && (
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Storno-Grund"
                        type="text"
                        fullWidth
                        value={reason}
                        onChange={handleReasonChange}
                    />
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onClose(false)}>Abbrechen</Button>
                <Button onClick={handleConfirm} color="primary" disabled={isStorno && reason.trim() === ''} >Ja</Button>
            </DialogActions>
        </Dialog>
    );
};

const TransportList = () => {
    // State variables
    const { user } = useUser(); // Anbemeldeter User
    const allowedRoles = ['user', 'dialysis'];
    const navigate = useNavigate();

    const [transports, setTransports] = useState([]);
    const [selectedTransport, setSelectedTransport] = useState(null);
    const [selectedSeriesIdentifier, setSelectedSeriesIdentifier] = useState(null);
    const [dialogAction, setDialogAction] = useState(null);
    const [dialogTitle, setDialogTitle] = useState('');
    const [dialogDescription, setDialogDescription] = useState('');
    const [dialogOpen, setDialogOpen] = useState(false);
    const [serverError, setServerError] = useState(null);
    // Add other necessary state variables
    const [searchQuery, setSearchQuery] = useState('');
    const [statusFilter, setStatusFilter] = useState({
        pending: true,
        approved: true,
        rejected: true,
    });
    const [dateFilter, setDateFilter] = useState(null);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const filterStatus = queryParams.get("filterStatus");
    const [isAutoLoading, setIsAutoLoading] = useState(false);

    // eslint-disable-next-line
    const [isLoading, setIsLoading] = useState(true); // for loading Icon

    // Fetch transports from the API
    useEffect(() => {
        fetchTransports();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (filterStatus) {
            // Update the status filter based on the received parameter
            setStatusFilter({
                pending: filterStatus === 'pending',
                approved: filterStatus === 'approved',
                rejected: filterStatus === 'rejected',
            });
        }
    }, [filterStatus]);

    useEffect(() => {
        console.log('useEffect AUTO-REFRESH');
        fetchTransports();

        // Timer einrichten, um fetchTransports alle 5 Minuten aufzurufen
        const interval = setInterval(() => fetchTransports(true), 300000);

        // Bereinigungsfunktion, um den Timer zu löschen, wenn die Komponente demontiert wird
        return () => clearInterval(interval);
        // eslint-disable-next-line
    }, []);


    const fetchTransports = async (autoRefresh = false) => {
        try {
            console.log('fetchTransports...');
            if (!autoRefresh) setIsLoading(true);
            else setIsAutoLoading(true);

            // API call to fetch transports
            let apiUrl = process.env.REACT_APP_API_URI + '/api/transports';

            // Check user role
            if (allowedRoles.includes(user?.role)) {
                apiUrl = process.env.REACT_APP_API_URI + '/api/transports/own?user_id=' + user.user_id;
            }

            const response = await fetch(apiUrl, {
                headers: {
                    'Authorization': user.api_key,
                },
            });
            if (!response.ok) {
                throw new Error('Error fetching transports');
            }
            const data = await response.json();
            //console.log(data);
            setTransports(data);
            await renewToken();
            setIsLoading(false);
            setIsAutoLoading(false);
        } catch (error) {
            console.error('Error fetching transports:', error);
            setIsLoading(false);
            // Handle the error
        }
    };

    const handleConfirmation = async (confirm, reason) => {
        setDialogOpen(false); // Close the dialog

        if (confirm) {
            let updatedTransport = { ...selectedTransport };

            // Workaround für JS Date und UTC timezone
            const datePart = dayjs(updatedTransport.departure_date).add(1, 'day').format('YYYY-MM-DD');
            const parsedDate = dayjs(datePart);
            const departureDateTime = parsedDate.isValid() ? parsedDate : null;
            updatedTransport.departure_date = departureDateTime;

            // Workaround für JS Date und UTC timezone bei birthdate im patient
            if (updatedTransport.birthdate) {
                const birthdatePart = dayjs(updatedTransport.birthdate).add(1, 'day').format('YYYY-MM-DD');
                const parsedBirthdate = dayjs(birthdatePart);
                const birthdate = parsedBirthdate.isValid() ? parsedBirthdate : null;
                updatedTransport.birthdate = birthdate;
            }

            if (dialogAction === 'löschen' || dialogAction === 'löschen_serie') {
                // Speichere den Storno-Grund
                updatedTransport.cancellation_reason = reason;
            }

            try {
                switch (dialogAction) {
                    case 'freigeben':
                        updatedTransport.status = 'approved';
                        await updateTransport(updatedTransport);
                        break;

                    case 'ablehnen':
                        updatedTransport.status = 'rejected';
                        await updateTransport(updatedTransport);
                        break;

                    case 'löschen':
                        await deleteTransport(updatedTransport.transport_id, reason);
                        break;

                    case 'löschen_serie':
                        await deleteTransportSeries(selectedSeriesIdentifier, reason);
                        break;

                    default:
                        break;
                }

                setServerError(null);
                // Refresh the transport list after successful action
                fetchTransports();
            } catch (error) {
                console.error('Error performing action:', error);
                setServerError(error.message);
            }
        }

        setSelectedTransport(null);
        setSelectedSeriesIdentifier(null);
        setDialogAction(null);
    };

    const updateTransport = async (updatedTransport) => {
        await fetch(`${process.env.REACT_APP_API_URI}/api/transports/${updatedTransport.transport_id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': user.api_key,
            },
            body: JSON.stringify(updatedTransport),
        });
    };

    const deleteTransport = async (transportId, reason) => {
        const response = await fetch(`${process.env.REACT_APP_API_URI}/api/transports/${transportId}/cancel`, {
            method: 'PUT',
            headers: {
                'Authorization': user.api_key,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ cancellation_reason: reason }),
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || 'Fehler beim Löschen des Transports.');
        }
    };

    const deleteTransportSeries = async (seriesIdentifier, reason) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URI}/api/transports/cancel_series/${seriesIdentifier}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': user.api_key,
                },
                body: JSON.stringify({ reason }),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Fehler beim Stornieren der Transport-Serie.');
            }

            console.log('Transport-Serie erfolgreich storniert.');
        } catch (error) {
            console.error('Error deleting transport series:', error.message);
            // Handle error case
        }
    };

    const handleEdit = (id) => {
        console.log('Bearbeiten:', id);
        navigate(`/transport/${id}`);
    };

    const handleDelete = (id) => {
        console.log("Löschen button clicked " + id);
        const fullTransport = findTransportById(id);
        if (fullTransport) {
            setSelectedTransport(fullTransport);
            setDialogAction('löschen');
            setDialogTitle('Stornieren');
            setDialogDescription(`Möchten Sie den Transport "${fullTransport.transport_type}" stornieren? Bitte geben Sie den Storno-Grund ein.`);
            setDialogOpen(true);
        }
    };

    const handleDeleteSeries = (seriesIdentifier) => {
        console.log("Löschen der Serie mit series_identifier " + seriesIdentifier);
        setSelectedSeriesIdentifier(seriesIdentifier);  // Hier wird das series_identifier gespeichert
        setDialogAction('löschen_serie');
        setDialogTitle('Stornieren');
        setDialogDescription(`Möchten Sie die gesamte Serie stornieren?`);
        setDialogOpen(true);
    };

    const handleFreigeben = (id) => {
        console.log("Freigabe button clicked " + id);
        const fullTransport = findTransportById(id);
        if (fullTransport) {
            setSelectedTransport(fullTransport);
            setDialogAction('freigeben');
            setDialogTitle('Freigabe');
            setDialogDescription(`Möchten Sie den Transport "${fullTransport.transport_type}" freigeben?`);
            setDialogOpen(true);
        }
    };

    const handleAblehnung = (id) => {
        console.log("Ablehnung button clicked " + id);
        const fullTransport = findTransportById(id);
        if (fullTransport) {
            setSelectedTransport(fullTransport);
            setDialogAction('ablehnen');
            setDialogTitle('Ablehnung');
            setDialogDescription(`Möchten Sie den Transport "${fullTransport.transport_type}" ablehnen?`);
            setDialogOpen(true);
        }
    };

    const handleStatusFilterChange = (status) => {
        setStatusFilter((prevStatusFilter) => ({
            ...prevStatusFilter,
            [status]: !prevStatusFilter[status],
        }));
    };

    function findTransportById(id) {
        const transportId = Number(id); // Konvertieren Sie die ID in eine Zahl
        for (let i = 0; i < transports.length; i++) {
            if (transports[i].transport_id === transportId) {
                return transports[i];
            }
        }
        return null;
    }

    // Define columns for the DataGrid
    const columns = [
        {
            field: 'transport_type',
            headerName: 'Transport Art',
            width: 130,
            renderCell: (params) => (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography variant="body2">
                        {params.row.transport_type}
                    </Typography>
                    {params.row.ambulatory && (
                        <Typography variant="caption" style={{ fontStyle: 'italic', color: 'gray' }}>
                            {params.row.return_trip ? 'Ambulant Rückfahrt' : 'Ambulant Hinfahrt'}
                        </Typography>
                    )}
                </div>
            ),
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 120,
            renderCell: (params) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {params.row.status === 'pending' ? (
                        <PauseIcon style={{ color: '#E46450', marginRight: 5 }} />
                    ) : params.row.status === 'approved' ? (
                        <ThumbUpOffAltIcon style={{ color: 'green', marginRight: 5 }} />
                    ) : (
                        <DoDisturbIcon style={{ color: 'red', marginRight: 5 }} />
                    )}
                    <Typography variant="body2" style={{ color: params.row.status === 'pending' ? '#E46450' : params.row.status === 'approved' ? 'green' : 'red' }}>
                        {params.row.status === 'pending' ? 'Ausstehend' : params.row.status === 'approved' ? 'Freigegeben' : 'Abgelehnt'}
                    </Typography>
                </div>
            ),
        },
        {
            field: 'departure_date',
            headerName: 'Transport Datum',
            width: 150,
            renderCell: (params) => {
                const departureDate = new Date(params.row.departure_date);
                const departureTime = params.row.departure_time;

                // Format the date with leading zeros for day and month
                const formattedDate = departureDate.toLocaleDateString('de-DE', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                });

                // Format the time as hours and minutes
                const formattedTime = new Date(`1970-01-01T${departureTime}`).toLocaleTimeString('de-DE', {
                    hour: '2-digit',
                    minute: '2-digit',
                });

                const formattedDateTime = `${formattedDate} ${formattedTime}`;
                const dynamicText = (() => {
                    if (params.row.transport_type === 'DIALYSEFAHRT') {
                        if (params.row.is_return_trip) { // assuming is_return_trip is a boolean that indicates if it's a return trip
                            return 'Wunschzeit Abfahrt';
                        } else {
                            return 'Ankunft Zielort';
                        }
                    } else if (params.row.transport_type === 'TERMINFAHRT') {
                        return 'Ankunft Zielort';
                    } else {
                        return 'Wunschzeit Abfahrt';
                    }
                })();

                return (
                    <div>
                        {formattedDateTime}
                        <div style={{ fontStyle: 'italic', fontSize: '12px', color: 'gray' }}>
                            {dynamicText}
                        </div>
                    </div>
                );
            },
        },
        {
            field: 'from_address',
            headerName: 'Von',
            width: 200,
            renderCell: (params) => {
                return (
                    <div>
                        <Typography variant="body2">{params.row.from_institution}</Typography>
                        <Typography variant="body2">{params.row.from_station}</Typography>
                        <Typography variant="body2">{params.row.from_street + ' ' + params.row.from_house_number}</Typography>
                        <Typography variant="body2">{params.row.from_postal_code + ' ' + params.row.from_city}</Typography>
                    </div>
                );
            },
        },
        {
            field: 'to_address',
            headerName: 'Nach',
            width: 200,
            renderCell: (params) => {
                return (
                    <div>
                        <Typography variant="body2">{params.row.to_institution}</Typography>
                        <Typography variant="body2">{params.row.to_station}</Typography>
                        <Typography variant="body2">{params.row.to_street + ' ' + params.row.to_house_number}</Typography>
                        <Typography variant="body2">{params.row.to_postal_code + ' ' + params.row.to_city}</Typography>
                    </div>
                );
            },
        },
        {
            field: 'patient_name',
            headerName: 'Patient',
            width: 140,
            renderCell: (params) => (
                <Typography variant="body2">
                    {`${params.row.first_name} ${params.row.last_name}`}
                </Typography>
            ),
        },
        {
            field: 'is_infectious',
            headerName: 'Inf.',
            width: 40,
            renderCell: (params) => (
                params.row.is_infectious ? <VaccinesIcon style={{ color: 'red' }} /> : null
            ),
        },
        {
            field: 'return_trip',
            headerName: 'Rück.',
            width: 40,
            renderCell: (params) => (
                params.row.return_trip ? <KeyboardReturnIcon /> : null
            ),
        },
        {
            field: 'is_recurring',
            headerName: 'Regel.',
            width: 40,
            renderCell: (params) => (
                params.row.is_recurring ? <AccessTimeIcon /> : null
            ),
        },
        /*  {
             field: 'transport_reason',
             headerName: 'Anlass',
             width: 100,
         }, */
        {
            field: 'actions2',
            type: 'actions',
            headerName: 'Aktionen',
            width: 120,
            getActions: (params) => {
                const actions = [
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Stornieren"
                        onClick={() => handleDelete(params.row.transport_id)}
                        showInMenu
                    />,
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Bearbeiten"
                        onClick={() => handleEdit(params.row.transport_id)}
                    />,
                ];

                if (user?.role === 'admin' || user?.role === 'poweruser') {
                    actions.push(
                        <GridActionsCellItem
                            icon={<ThumbUpOffAltIcon />}
                            label="Freigeben"
                            onClick={() => handleFreigeben(params.row.transport_id)}
                            showInMenu
                        />,
                        <GridActionsCellItem
                            icon={<DoDisturbIcon />}
                            label="Ablehnen"
                            onClick={() => handleAblehnung(params.row.transport_id)}
                            showInMenu
                        />
                    );
                }

                if (params.row.is_recurring) {
                    actions.push(
                        <GridActionsCellItem
                            icon={<DeleteIcon />}
                            label="Serie regelmäßiger Transporte stornieren"
                            onClick={() => handleDeleteSeries(params.row.series_identifier)}
                            showInMenu
                        />
                    );
                }
                return actions;
            },
        },
        {
            field: 'create_date',
            headerName: 'Erstellt am/von',
            width: 150,
            renderCell: (params) => {
                const createDate = new Date(params.row.create_date);

                // Format the date with leading zeros for day and month
                const formattedDate = createDate.toLocaleDateString('de-DE', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                });

                // Format the time as hours and minutes
                const formattedTime = createDate.toLocaleTimeString('de-DE', {
                    hour: '2-digit',
                    minute: '2-digit',
                });

                const formattedDateTime = `${formattedDate} ${formattedTime}`;

                return (
                    <div>
                        {formattedDateTime}
                        <div style={{ fontStyle: 'italic', fontSize: '12px', color: 'gray' }}>
                            {params.row.username}
                        </div>
                        <div style={{ fontStyle: 'italic', fontSize: '12px', color: 'gray' }}>
                            {params.row.object_institution}
                        </div>
                        {params.row.auto_generated && (
                            <div style={{ fontStyle: 'italic', fontSize: '12px', color: 'blue' }}>
                                Automatisch generiert
                            </div>
                        )}
                    </div>
                );
            },
        },
    ];


    const handleCreateTransport = () => {
        navigate('/transport');
    };

    const handleSearch = (event) => {
        const newSearchQuery = event.target.value.toLowerCase();
        setSearchQuery(newSearchQuery);
    };

    const handleDateFilterChange = (event) => {
        // eslint-disable-next-line
        const { name, value } = event.target;
        setDateFilter(value);
    };

    const handleClearDateFilter = () => {
        setDateFilter(null);
    };

    const handleClearSearch = (event) => {
        setSearchQuery('');
    };




    const filteredTransports = transports
        .filter((transport) =>
            (transport.transport_type && transport.transport_type.toLowerCase().includes(searchQuery)) ||
            (transport.from_institution && transport.from_institution.toLowerCase().includes(searchQuery)) ||
            (transport.from_street && transport.from_street.toLowerCase().includes(searchQuery)) ||
            (transport.from_postal_code && transport.from_postal_code.toLowerCase().includes(searchQuery)) ||
            (transport.from_city && transport.from_city.toLowerCase().includes(searchQuery)) ||
            (transport.to_institution && transport.to_institution.toLowerCase().includes(searchQuery)) ||
            (transport.to_street && transport.to_street.toLowerCase().includes(searchQuery)) ||
            (transport.to_postal_code && transport.to_postal_code.toLowerCase().includes(searchQuery)) ||
            (transport.to_city && transport.to_city.toLowerCase().includes(searchQuery)) ||
            (transport.first_name && transport.last_name && (`${transport.first_name} ${transport.last_name}`).toLowerCase().includes(searchQuery)) ||
            (transport.transport_reason && transport.transport_reason.toLowerCase().includes(searchQuery))
        )
        .filter((transport) =>
            // Filterbedingungen für den Status-Filter hier einfügen
            (statusFilter.pending && transport.status === 'pending') ||
            (statusFilter.approved && transport.status === 'approved') ||
            (statusFilter.rejected && transport.status === 'rejected')
        )
        .filter((transport) => {
            if (!dateFilter) {
                return true; // Wenn kein Datum ausgewählt ist, alle Transports anzeigen
            }

            const transportDate = new Date(transport.departure_date);
            const selectedDate = new Date(dateFilter);

            return transportDate.toDateString() === selectedDate.toDateString();
        });

    return (
        <Box m={1} >
            <Box display="flex" alignItems="center" justifyContent="space-between" mb={1}>
                <Stack justifyItems="flex-start" alignItems="center" direction="row">
                    <Typography variant="h5" color="#E46450" component="h1" gutterBottom mt={1}>
                        Offene Bestellungen
                    </Typography>

                    {isLoading && !isAutoLoading && (
                        <CircularProgress color="inherit" />
                    )}
                    {(!isLoading) && (
                        <IconButton ml={2} size="large" aria-label="refresh" onClick={fetchTransports}>
                            <RefreshIcon fontSize="large" />
                        </IconButton>
                    )}
                </Stack>

                <TextField
                    id="search"
                    label="Schnellsuche"
                    variant="outlined"
                    size="small"
                    value={searchQuery}
                    onChange={handleSearch}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                {searchQuery && (
                                    <IconButton
                                        aria-label="Clear search"
                                        size="small"
                                        onClick={handleClearSearch}
                                        edge="end"
                                    >
                                        <ClearIcon />
                                    </IconButton>
                                )}
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                    sx={{
                        width: '300px',
                    }}
                />
                <Button variant="outlined" color="primary" onClick={handleCreateTransport} startIcon={<AddIcon />}>
                    Neuen Transport bestellen
                </Button>
            </Box>
            {user?.role !== 'user' && (
                <Box display="flex" alignItems="center" justifyContent="space-between" mb={1} sx={{
                    border: '1px solid #ccc', // Leicht grauer Rahmen
                    borderRadius: '4px', // Runde Ecken (optional)
                    padding: '12px', // Innenabstand
                }}>
                    <Stack justifyItems="flex-start" alignItems="center" direction="row">
                        <FilterAltIcon sx={{ marginRight: 1 }} /> {/* Filter-Symbol */}
                        <Typography variant="body1" sx={{ marginRight: 8, fontWeight: 'bold' }}>
                            Filter
                        </Typography>

                        {/* Status Filter */}
                        <Box display="flex" alignItems="center" mr={4}>
                            <Typography variant="body2" sx={{ marginRight: 1 }}>
                                Status:
                            </Typography>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={statusFilter.pending}
                                        onChange={() => handleStatusFilterChange('pending')}
                                        color="primary"
                                    />
                                }
                                label={
                                    <Typography variant="body2">Ausstehend</Typography>
                                }
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={statusFilter.approved}
                                        onChange={() => handleStatusFilterChange('approved')}
                                        color="primary"
                                    />
                                }
                                label={
                                    <Typography variant="body2">Freigegeben</Typography>
                                }
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={statusFilter.rejected}
                                        onChange={() => handleStatusFilterChange('rejected')}
                                        color="primary"
                                    />
                                }
                                label={
                                    <Typography variant="body2">Abgelehnt</Typography>
                                }
                            />
                        </Box>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='de'>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <DatePicker
                                    label="Transport Datum"
                                    name="filterDate"
                                    value={dateFilter}
                                    onChange={(date) => handleDateFilterChange({ target: { name: 'filterDate', value: date } })}
                                    margin="normal"
                                    fullWidth
                                    format="DD.MM.YYYY"
                                />
                                {dateFilter && (
                                    <IconButton
                                        size="small"
                                        onClick={handleClearDateFilter}
                                        edge="end"
                                    >
                                        <ClearIcon />
                                    </IconButton>
                                )}
                            </div>
                        </LocalizationProvider>
                    </Stack>
                </Box>
            )}
            {serverError && <Alert severity="error" sx={{ my: 2 }}>{serverError}</Alert>}

            <div style={{ width: '100%' }}>
                <DataGrid
                    rows={filteredTransports}
                    columns={columns}
                    pageSize={5}
                    rowsPerPageOptions={[5, 10, 20]}
                    getRowId={(row) => row.transport_id}
                    rowHeight={80}
                />
                {(selectedTransport || selectedSeriesIdentifier) && (
                    <ConfirmationDialog
                        open={dialogOpen}
                        onClose={handleConfirmation}
                        onConfirm={handleConfirmation}
                        title={dialogTitle}
                        description={dialogDescription}
                    />
                )}
            </div>

            {/* Confirmation dialog for actions (delete, approve, etc.) */}
        </Box >
    );
};

export default TransportList;
