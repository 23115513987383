import TopMenu from "./components/topmenu";
import Footer from "./components/footer";

function MainLayout({ children }) {
    return (
        <>
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', backgroundColor: '#FFFFFF' }}>
                <TopMenu />
                <div style={{ padding: '16px' }}>
                    <div style={{ flex: 1 }}>
                        {children} {/* Hier wird der Inhalt der jeweiligen Seite angezeigt */}
                    </div>
                </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Footer />
            </div>
        </>

    );
}

export default MainLayout;