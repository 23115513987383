import React, { useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { useUser } from './userContext';

const InstitutionAutocomplete = ({ value, onChange, label = "Institution", variant = "outlined", ...otherProps }) => {
    const { user } = useUser(); // Anbemeldeter User
    const [options, setOptions] = useState([]);
    // eslint-disable-next-line
    const [open, setOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');

    const fetchInstitutions = async (start, limit, searchTerm) => {
        try {

            if (searchTerm && searchTerm.length > 0) {

                const response = await fetch(`${process.env.REACT_APP_API_URI}/api/lazyinstitutions?start=${start}&limit=${limit}&searchTerm=${searchTerm}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': user.api_key,
                    },
                });
                const data = await response.json();
                if (Array.isArray(data)) {
                    setOptions(data);
                } else {
                    setOptions([]); // Vermeiden von Zustandsmutation zu Nicht-Array-Typen
                }
            }
        } catch (error) {
            console.error('Error fetching institutions:', error);
        }
    };

    return (
        <Autocomplete
            value={value}
            onChange={onChange}
            onOpen={() => {
                setOpen(true);
                fetchInstitutions(0, 50, searchTerm);
            }}
            onClose={() => {
                setOpen(false);
            }}
            onInputChange={(event, newInputValue) => {
                setSearchTerm(newInputValue);
                fetchInstitutions(0, 50, newInputValue);
            }}
            options={options}
            filterOptions={(options) => options}
            isOptionEqualToValue={(option, value) => option.institution_id === value.institution_id}
            getOptionLabel={(option) => {
                let label = option.object_institution || 'Unbekannt';
                if (option.station) { // Prüfen, ob station einen Wert hat
                    label += ` - Station: ${option.station}`;
                }
                return label;
            }}
            renderOption={(props, option) => (
                <li {...props} key={option.institution_id || option.object_institution}>
                    <div>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div>
                                {option.object_institution || 'Unbekannt'}
                                {option.station ? ` - Station: ${option.station}` : ''}
                            </div>
                            <div style={{ fontSize: '0.8rem', color: 'gray' }}>
                                {option.street_and_number || 'Unbekannt'} -
                                {option.city || 'Unbekannt'}
                            </div>
                        </div>
                    </div>
                </li>
            )}
            renderInput={(params) => (
                <TextField {...params} label={label} variant={variant} {...otherProps} />
            )}
        />
    );
};

export default InstitutionAutocomplete;