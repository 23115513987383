import React, { useEffect, useState } from 'react';
import {
    Typography,
    TextField,
    FormControl,
    FormLabel,
    Box,
    Tabs,
    Tab,
} from '@mui/material';
import InstitutionAutocomplete from '../components/institutionAutocomplete';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { useUser } from '../components/userContext';

const FromToStep = ({ formData, setFormData, activeTab }) => {
    const [selectedTab, setSelectedTab] = useState(0);
    const { user } = useUser(); // Angemeldeter User
    const allowedRoles = ['user', 'dialysis'];
    const isTransportApproved = formData.status === "approved" && allowedRoles.includes(user?.role);

    useEffect(() => {
        setSelectedTab(activeTab);
    }, [activeTab]);

    const handleInstitutionChange = (name, newValue) => {
        if (newValue) {
            setFormData(prevData => ({
                ...prevData,
                [`${name}_institution`]: newValue,
                [`${name}_institution_json`]: newValue,
                [`${name}_station`]: newValue.station,
                [`${name}_street`]: newValue.street_and_number.split(' ')[0], // Annahme, dass Straße und Nummer durch ein Leerzeichen getrennt sind
                [`${name}_house_number`]: newValue.street_and_number.split(' ')[1],
                [`${name}_postal_code`]: newValue.postal_code,
                [`${name}_city`]: newValue.city,
            }));
        } else {
            setFormData(prevData => ({
                ...prevData,
                [`${name}_institution`]: '',
                [`${name}_institution_json`]: null,
                [`${name}_station`]: '',
                [`${name}_street`]: '',
                [`${name}_house_number`]: '',
                [`${name}_postal_code`]: '',
                [`${name}_city`]: '',
            }));
        }
    };

    const handleChangeTab = (event, newValue) => {
        setSelectedTab(newValue);
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const areFromFieldsEmpty = () => {
        return !formData.from_street || !formData.from_house_number || !formData.from_postal_code || !formData.from_city;
    };
    const areToFieldsEmpty = () => {
        return !formData.to_street || !formData.to_house_number || !formData.to_postal_code || !formData.to_city;
    };

    return (
        <Box mt={4} mb={2} pt={12} pl={4} pr={4} pb={4} style={{
            background: 'linear-gradient(to bottom, #FFFFFF, #F5F5F5)', borderRadius: '10px', width: '100%', border: '1px solid #F5F5F5', position: 'relative', zIndex: 1,
        }}>
            <Typography variant="h6">Start- und Zieladresse</Typography>
            <Tabs
                value={selectedTab}
                onChange={handleChangeTab}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
            >
                <Tab
                    label={
                        <Box alignItems="center" display="flex">
                            Start (Von)
                            {areFromFieldsEmpty() && (
                                <span style={{ marginLeft: '8px', color: 'red' }}>
                                    <WarningAmberIcon fontSize="small" />
                                </span>
                            )}
                        </Box>
                    }
                />
                <Tab
                    label={
                        <Box alignItems="center" display="flex">
                            Ziel (Nach)
                            {areToFieldsEmpty() && (
                                <span style={{ marginLeft: '8px', color: 'red' }}>
                                    <WarningAmberIcon fontSize="small" />
                                </span>
                            )}
                        </Box>
                    }
                />
            </Tabs>
            {selectedTab === 0 && (
                <FormControl component="fieldset" margin="normal" fullWidth>
                    <FormLabel component="legend" style={{ marginBottom: '20px' }}>Wo soll der Patient abgeholt werden?</FormLabel>
                    <InstitutionAutocomplete
                        value={formData.from_institution_json || null}
                        onChange={(event, newValue) => handleInstitutionChange('from', newValue)}
                        label="Institution eingeben (Klinik, Alten- oder Pflegeheim, Arztpraxis,...)"
                        disabled={isTransportApproved}
                    />
                    <TextField
                        label="Station"
                        name="from_station"
                        value={formData.from_station || ''}
                        onChange={handleChange}
                        margin="normal"
                        fullWidth
                    />
                    <TextField
                        label="Zimmer"
                        name="from_room"
                        value={formData.from_room || ''}
                        onChange={handleChange}
                        margin="normal"
                        fullWidth
                    />
                    <Box display="flex">
                        <TextField
                            label="Straße *"
                            name="from_street"
                            value={formData.from_street || ''}
                            onChange={handleChange}
                            margin="normal"
                            fullWidth
                            style={{ flex: '0 0 70%', marginRight: '8px' }}
                            error={!formData.from_street}
                            helperText={!formData.from_street && 'Von: Straße ist erforderlich'}
                            disabled={isTransportApproved}
                        />
                        <TextField
                            label="Hausnummer *"
                            name="from_house_number"
                            value={formData.from_house_number || ''}
                            onChange={handleChange}
                            margin="normal"
                            fullWidth
                            style={{ flex: '1 1 auto' }}
                            error={!formData.from_house_number}
                            helperText={!formData.from_house_number && 'Von: Hausnummer ist erforderlich'}
                            disabled={isTransportApproved}
                        />
                    </Box>
                    <Box display="flex">
                        <TextField
                            label="Postleitzahl *"
                            name="from_postal_code"
                            value={formData.from_postal_code || ''}
                            onChange={handleChange}
                            margin="normal"
                            fullWidth
                            style={{ flex: '0 0 30%', marginRight: '8px' }}
                            error={!formData.from_postal_code}
                            helperText={!formData.from_postal_code && 'Von: Postleitzahl ist erforderlich'}
                            disabled={isTransportApproved}
                        />
                        <TextField
                            label="Stadt *"
                            name="from_city"
                            value={formData.from_city || ''}
                            onChange={handleChange}
                            margin="normal"
                            fullWidth
                            style={{ flex: '1 1 auto' }}
                            error={!formData.from_city}
                            helperText={!formData.from_city && 'Von: Stadt ist erforderlich'}
                            disabled={isTransportApproved}
                        />
                    </Box>
                    <TextField
                        label="Zusätzliche Informationen"
                        name="from_additional_info"
                        value={formData.from_additional_info || ''}
                        onChange={handleChange}
                        margin="normal"
                        fullWidth
                        multiline
                        rows={3}
                    />
                </FormControl>
            )}

            {selectedTab === 1 && (
                <FormControl component="fieldset" margin="normal" fullWidth>
                    <FormLabel component="legend" style={{ marginBottom: '20px' }}>Wohin soll der Patient gebracht werden?</FormLabel>
                    <InstitutionAutocomplete
                        value={formData.to_institution_json || null}
                        onChange={(event, newValue) => handleInstitutionChange('to', newValue)}
                        label="Institution eingeben (Klinik, Alten- oder Pflegeheim, Arztpraxis,...)"
                        disabled={isTransportApproved}
                    />
                    <TextField
                        label="Station"
                        name="to_station"
                        value={formData.to_station || ''}
                        onChange={handleChange}
                        margin="normal"
                        fullWidth
                    />
                    <TextField
                        label="Zimmer"
                        name="to_room"
                        value={formData.to_room || ''}
                        onChange={handleChange}
                        margin="normal"
                        fullWidth
                    />
                    <Box display="flex">
                        <TextField
                            label="Straße *"
                            name="to_street"
                            value={formData.to_street || ''}
                            onChange={handleChange}
                            margin="normal"
                            fullWidth
                            style={{ flex: '0 0 70%', marginRight: '8px' }}
                            error={!formData.to_street}
                            helperText={!formData.to_street && 'Nach: Straße ist erforderlich'}
                            disabled={isTransportApproved}
                        />
                        <TextField
                            label="Hausnummer *"
                            name="to_house_number"
                            value={formData.to_house_number || ''}
                            onChange={handleChange}
                            margin="normal"
                            fullWidth
                            style={{ flex: '1 1 auto' }}
                            error={!formData.to_house_number}
                            helperText={!formData.to_house_number && 'Nach: Hausnummer ist erforderlich'}
                            disabled={isTransportApproved}
                        />
                    </Box>
                    <Box display="flex">
                        <TextField
                            label="Postleitzahl *"
                            name="to_postal_code"
                            value={formData.to_postal_code || ''}
                            onChange={handleChange}
                            margin="normal"
                            fullWidth
                            style={{ flex: '0 0 30%', marginRight: '8px' }}
                            error={!formData.to_postal_code}
                            helperText={!formData.to_postal_code && 'Nach: Postleitzahl ist erforderlich'}
                            disabled={isTransportApproved}
                        />
                        <TextField
                            label="Stadt *"
                            name="to_city"
                            value={formData.to_city || ''}
                            onChange={handleChange}
                            margin="normal"
                            fullWidth
                            style={{ flex: '1 1 auto' }}
                            error={!formData.to_city}
                            helperText={!formData.to_city && 'Nach: Stadt ist erforderlich'}
                            disabled={isTransportApproved}
                        />
                    </Box>
                    <TextField
                        label="Zusätzliche Informationen"
                        name="to_additional_info"
                        value={formData.to_additional_info || ''}
                        onChange={handleChange}
                        margin="normal"
                        fullWidth
                        multiline
                        rows={3}
                    />
                </FormControl>
            )}
        </Box>
    );
};

export default FromToStep;
