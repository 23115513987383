import React, { useEffect, useState } from 'react';
import { Button, TextField, Container, Typography, Alert, Stack, Link, Box, Collapse } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../components/userContext'; // Importieren Sie den UserContext

const Login = () => {
    const [formData, setFormData] = useState({
        email: '',
        password: '',
    });

    const navigate = useNavigate();
    const { setUser } = useUser(); // Verwenden Sie den UserContext

    const [serverError, setServerError] = useState({});
    const [errors, setErrors] = useState({});
    const [open, setOpen] = useState(false);
    const [willPasswordChange, setWillPasswordChange] = useState(false);
    const [isChrome, setIsChrome] = useState(false);

    useEffect(() => {
        const isChromeBrowser = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
        setIsChrome(isChromeBrowser);
    }, []);

    const handleChange = (event) => {
        const { name, value } = event.target;

        const lowercaseValue = name === 'email' ? value.toLowerCase() : value;

        setFormData((prevData) => ({
            ...prevData,
            [name]: lowercaseValue,
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        let isValid = true;
        let newErrors = {};

        if (!validateEmail(formData.email)) {
            isValid = false;
            newErrors.email = "Ungültige E-Mail-Adresse";
        }

        if (willPasswordChange) {
            await handlePasswordReset();
            return;
        }

        if (!formData.password) newErrors.password = 'Passwort ist erforderlich';

        setErrors(newErrors);

        if (isValid) {
            console.log('Try to logon on: ' + process.env.REACT_APP_API_URI + '/api/login');
            // API-Aufruf zur Überprüfung der Anmeldeinformationen
            fetch(process.env.REACT_APP_API_URI + '/api/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    username: formData.email,
                    password: formData.password,
                }),
            })
                .then(response => response.json())
                .then(data => {
                    if (data.status === 'success') {
                        // Hier können Sie den JWT-Token speichern, falls Sie ihn für nachfolgende Anfragen benötigen
                        localStorage.setItem('token', data.token);

                        // Setzen Sie den Benutzer im Kontext
                        setUser({
                            token: data.token,
                            user_id: data.user_id,
                            username: data.username,
                            institutionId: data.institution_id,
                            registrationStatus: data.registration_status,
                            accountActivated: data.account_activated,
                            role: data.role,
                            api_key: data.api_key,
                        });

                        console.log('user signed in: ' + data.username + ' id:' + data.user_id);

                        // Weiterleitung zur Dashboard-Seite
                        navigate('/dashboard');
                    } else {
                        // Fehlerbehandlung, z.B. Anzeige einer Fehlermeldung
                        setErrors({ server: data.message });
                    }
                })
                .catch(error => {
                    console.error('Fehler beim Anmelden:', error);
                    setErrors({ server: 'Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.' });
                });
        }

    };

    const validateEmail = (email) => {
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        return emailRegex.test(email);
    };

    const handlePasswordResetClick = (event) => {
        event.preventDefault();
        setWillPasswordChange(true);
    };

    const handlePasswordReset = async () => {
        // clear the errors
        setErrors({});
        setServerError("");

        // validate the inputs
        if (!validateEmail(formData.email)) {
            setErrors({ email: "Ungültige E-Mail-Adresse" });
            return;
        }

        try {
            console.log("Send PW reset mail to: " + formData.email);

            const response = await fetch(`${process.env.REACT_APP_API_URI}/api/users/send-reset-email`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: formData.email,
                }),
            });

            if (response.ok) {
                setOpen(true);
            } else {
                const errorData = await response.json();
                throw new Error(errorData.message);
            }
        } catch (error) {
            console.error(error.message);
            setErrors({ server: error.message });
        }
    };


    const backgroundImageUrl = `${process.env.REACT_APP_BASE_URL}/login_back.jpg`;

    return (
        <Box
            component="main"
            sx={{
                position: 'fixed', // Fixiert den Hintergrund
                top: 0,
                left: 0,
                width: '100%', // Setzt die Breite auf 100%
                height: '100%', // Setzt die Höhe auf 100%
                backgroundImage: `url('${backgroundImageUrl}')`, // Dynamische Hintergrundbild-URL
                backgroundSize: 'cover', // Deckt den gesamten Container ab
                backgroundPosition: 'center', // Zentriert das Bild
                zIndex: -1, // Stellt sicher, dass der Hintergrund hinter dem Inhalt liegt
            }}
        >
            <Container maxWidth="xs" sx={{ mt: 10, mb: 20, backgroundColor: 'rgba(255, 255, 255, 1)', borderRadius: '10px', p: 4 }}>
                <img src="drk_bos_logo_full.png" alt="Logo" style={{ height: '54px' }} />
                <Typography sx={{ mt: 6 }} variant="h5" component="h1" gutterBottom textAlign="center">
                    KTW-Anmeldung
                </Typography>
                {errors && Object.values(errors).join(', ') && <Alert severity="error" sx={{ my: 2 }}>{Object.values(errors).join(', ')}</Alert>}
                {typeof serverError === 'string' && serverError && <Alert severity="error" sx={{ my: 2 }}>{serverError}</Alert>}
                <Box component="form" onSubmit={handleSubmit}>
                    <TextField
                        fullWidth
                        autoFocus
                        label="E-Mail-Adresse"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        margin="normal"
                        error={!!errors.email}
                        helperText={errors.email}
                        InputLabelProps={isChrome ? {
                            shrink: true,
                            style: { position: 'absolute', top: '-15px' },
                        } : {}}
                    />
                    {willPasswordChange === false && (
                        <TextField
                            fullWidth
                            label="Passwort"
                            name="password"
                            type="password"
                            value={formData.password}
                            onChange={handleChange}
                            margin="normal"
                            error={!!errors.password}
                            helperText={errors.password}
                            sx={{ mb: 2 }}
                            InputLabelProps={isChrome ? {
                                shrink: true,
                                style: { position: 'absolute', top: '-15px' },
                            } : {}}
                        />)}
                    <Button type="submit" variant="contained" color="primary" fullWidth style={{ marginTop: '16px' }}>
                        {willPasswordChange === false ? 'Anmelden' : 'Neues Passwort anfordern'}
                    </Button>
                    <Stack direction="row" sx={{ mt: 4 }} alignItems="center" justifyContent="center">
                        <Typography variant="body2">Ich habe noch keinen Zugang.</Typography>
                        <Link ml={1} variant="body2" href="/registration">Jetzt registrieren!</Link>
                    </Stack>
                    {willPasswordChange === false && (
                        <Stack direction="row" sx={{ mt: 1 }} alignItems="center" justifyContent="center">
                            <Typography mr={1} variant="body2">Passwort vergessen?</Typography>
                            <Link component="button" variant="body2" onClick={handlePasswordResetClick} >Neues Passwort per Mail anfordern</Link>
                        </Stack>
                    )}
                    {willPasswordChange === true && (
                        <Stack direction="row" sx={{ mt: 1, mb: 2, }} alignItems="center" justifyContent="center">
                            <Link ml={1} variant="body2" href="/login">Zurück zur Anmeldung</Link>
                        </Stack>
                    )}
                </Box>
                <Collapse in={open} >
                    <Alert mt={2} severity="success">Es wurde eine Mail für das Zurücksetzen des Passworts versendet!</Alert>
                </Collapse>
            </Container>
        </Box>
    );
};

export default Login;
