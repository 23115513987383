import { Container, Divider, Paper, Typography } from '@mui/material';

export default function Datenschutz() {
    return (
        <Container maxWidth="md">
            <Paper elevation={3} sx={{ padding: '20px', borderRadius: '10px', background: '#f7f7f7' }}>
                <Typography variant="h6" color="#E46450" sx={{ fontWeight: 'bold', marginBottom: '8px' }}>Datenschutzerklärung</Typography>
                <Divider />
                <Typography variant="body1" align="justify" mt={2}>
                    <b>Einleitung</b>
                    <br />
                    Wir, die DRK Rettungsdienst Bodensee-Oberschwaben gGmbH, nehmen den Schutz personenbezogener Daten und damit eingeschlossen auch die Betroffenenrechte sehr ernst.
                    <br />
                    Mit diesen Erläuterungen zum Datenschutz möchten wir Sie darüber informieren,
                    <ul>
                        <li>welche personenbezogenen Daten wir beim Besuch unserer Webseite und</li>
                        <li>beim Ausfüllen unserer Onlineformulare oder</li>
                        <li>unseres Kontaktformulars speichern und</li>
                        <li>zu welchen Zwecken wir diese Daten verwenden und</li>
                        <li>welche Rechte Ihnen in diesem Zusammenhang zustehen.</li>
                    </ul>
                    Wir kontrollieren regelmäßig die internen Prozesse sowie die technischen und organisatorischen Maßnahmen, um zu gewährleisten, dass die Verarbeitung in seinem Verantwortungsbereich im Einklang mit den Anforderungen des geltenden Datenschutzrechts erfolgt und der Schutz der Rechte der betroffenen Person gewährleistet wird.
                    <br />
                    <br />
                    <b>Verantwortlicher</b>
                    <br />
                    Die DRK Rettungsdienst Bodensee-Oberschwaben gGmbH betreibt die vorliegende Webseite und ist Verantwortlicher für die Verarbeitung personenbezogener Daten gemäß Datenschutzgrundverordnung. Die Erhebung, Verarbeitung und Nutzung Ihrer personenbezogenen Daten erfolgt in Übereinstimmung mit den geltenden deutschen und europäischen gesetzlichen Vorschriften.
                    <br />
                    Deutsches Rotes Kreuz
                    <br />
                    Rettungsdienst Bodensee-Oberschwaben gGmbH
                    <br />
                    Birkenweg 12
                    <br />
                    88250 Weingarten
                    <br />
                    Deutschland
                    <br />
                    Tel: 0751 509157-0
                    <br />
                    Mail: kontakt(at)drk-bos.de
                    <br />
                    Vertretungsberechtigte
                    <br />
                    Volker Geier | Geschäftsführer
                    <br />
                    Frank Panschar | Prokurist
                    <br />
                    <br />
                    <b>Datenschutzbeauftragte(r)</b>
                    <br />
                    Die Beachtung geltenden Datenschutzrechtes unterliegt einer ständigen Überprüfung durch unseren Datenschutzbeauftragten. Dieser hat seinen Sitz bei unserem DRK Landesverband Baden-Württemberg in Stuttgart. Für Fragen stehen folgende Kontaktmöglichkeiten zur Verfügung:
                    <br />
                    Stefanie Schäufele
                    <br />
                    Tel: 0711 5505-178
                    <br />
                    E-Mail: datenschutz(at)drk-bw.de
                    <br />
                    <br />
                    <b>Betroffenenrechte</b>
                    <br />
                    Als betroffene Person haben Sie nach Art. 15 ff. DSGVO folgende Rechte.
                    <br />
                    Wir geben Ihnen gerne Auskunft über die von Ihnen gespeicherten Daten. Zusätzlich können Sie jederzeit die Berichtigung, Löschung, Sperrung oder Übertragung Ihrer Daten bei uns verlangen. Weiterhin können Sie sich an die Aufsichtsbehörde wenden.
                    <br />
                    Eine Übertragung Ihrer Interessenten- oder Kunden-Daten, die Sie in Onlineformularen eingeben, in ein Drittland findet nicht statt. Hinsichtlich der (anonymen) IP-Adresse beachten Sie bitte Punkt 5. Website.
                    <br />
                    <br />
                    <b>Widerruf</b>
                    <br />
                    Abgegebene Einwilligungen können Sie jederzeit widerrufen. Der Widerruf kann ohne Angaben von Gründen, formlos und mit Wirkung für die Zukunft geschehen.
                    <br />
                    <br />
                    <b>Zwecke der Verarbeitung</b>
                    <br />
                    Zu folgenden Zwecken verarbeiten wir Ihre personenbezogenen Daten:
                    <ul>
                        <li>Rettungsdienst ⇒ Rechnungserstellung usw.</li>
                        <li>ehrenamtliche Mitarbeit ⇒ um auf Ihre Einsatzwünsche ein passendes Angebot zu finden</li>
                        <li>Kontaktformular ⇒ um Ihre Wünsche, die Sie uns per Kontaktformular mitteilen, umsetzen zu können</li>
                        <li>Stellenbewerbung ⇒ um Ihre Stellenbewerbungen bearbeiten zu können</li>
                        <li>Spenden ⇒ Beitragseinzug, Beitragshistorie, Spendenquittung, Informationsbereitstellung, usw.</li>
                    </ul>
                    <br />
                    <b>Cookies</b>
                    <br />
                    Diese Website verwendet Cookies. Cookies sind verschlüsselte Textfolgen, die eine Website auf dem Computer des Benutzers / der Benutzerin speichern.
                    <br />
                    Cookies ermöglichen es diesem Internetauftritt, sich an Ihre Bedürfnisse anzupassen. Zusätzlich werden Cookies verwendet, um auf dieser Internetseite die Nutzung verschiedener Seiten zu messen.
                    <br />
                    Die Arten von Cookies werden als „Session-Cookies“ und „Persistent-Cookies“ bezeichnet. Session-Cookies sind temporär und werden automatisch gelöscht, sobald Sie den Internetauftritt verlassen. Alle mit diesen Cookies erhobenen Informationen sind anonym und erlauben keinen Rückschluss auf die Person des Besuchers.
                    <br />
                    Persistent-Cookies verbleiben auf der Festplatte Ihres Computers, bis Sie diese löschen. Die Cookies werden nicht benutzt, um Daten über Ihre Besuche auf anderen Seiten einzuholen. Cookies beschädigen oder beeinträchtigen die Funktionsweise Ihres Computers, Ihrer Programme oder Dateien nicht.
                    <br />
                    <br />
                    <b>Google Anwendungen</b>
                    <br />
                    <b>Google Allgemein</b>
                    <br />
                    Wir weisen darauf hin, dass in den USA kein mit Deutschland und der EU vergleichbares Datenschutzniveau herrscht und wir in den USA für den Verbleib Ihrer Daten nicht garantieren können.
                    <br />
                    Google verwendet die so erlangten Informationen unter anderem dazu, Bücher und andere Druckerzeugnisse zu digitalisieren sowie Dienste wie Google Street View und Google Maps zu optimieren (bspw. Hausnummern- und Straßennamenerkennung).
                    <br />
                    <b>Einsatz von Google-Maps für PLZ-Suche und Anfahrtswegberechnung</b>
                    <br />
                    Wir setzen auf unserer Seite die Komponente „Google Maps“ der Firma Google Inc., nachfolgend „Google“, ein.
                    <br />
                    Betreiber des Services: Google Inc.
                    Adresse: Google Inc.; 1600 Amphitheatre Parkway; Mountain View; CA 94043 USA
                    Bei jedem einzelnen Aufruf der Komponente „Google-Maps“ fallen bei Google folgende Daten an:
                    <ul>
                        <li>IP-Adresse des anfragenden Rechners</li>
                        <li>Datum und Uhrzeit des Zugriffs</li>
                        <li>Name und URL der abgerufenen Datei</li>
                        <li>Website, von der aus der Zugriff erfolgt (Referrer-URL)</li>
                        <li>Verwendeter Browser und ggf. das Betriebssystem Ihres Rechners sowie der Name Ihres Access-Providers</li>
                    </ul>
                    Bei jedem einzelnen Aufruf der Komponente „Google Maps“ wird von Google ein Cookie gesetzt, um bei der Anzeige der Seite, auf der die Komponente „Google Maps“ integriert ist, Nutzereinstellungen und -daten zu verarbeiten. Dieses Cookie wird im Regelfall nicht durch das Schließen des Browsers gelöscht, sondern läuft nach einer bestimmten Zeit ab, soweit es nicht von Ihnen zuvor manuell gelöscht wird.
                    <br />
                    Wenn Sie mit dieser Verarbeitung Ihrer Daten nicht einverstanden sind, so besteht die Möglichkeit, den Service von „Google Maps“ zu deaktivieren und auf diesem Weg die Übertragung von Daten an Google zu verhindern. Dazu müssen Sie die Java-Script-Funktion in Ihrem Browser deaktivieren. Wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall „Google Maps“ nicht oder nur eingeschränkt nutzen können.
                    <br />
                    Die Nutzung von „Google Maps“ und der über „Google Maps“ erlangten Informationen erfolgt gemäß den Google-Nutzungsbedingungen http://www.google.de/intl/de/policies/terms/regional.html sowie den zusätzlichen Geschäftsbedingungen für „Google Maps“ https://www.google.com/intl/de_de/help/terms_maps.html

                </Typography>
            </Paper>
        </Container>


    );
}
