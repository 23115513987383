
import PropTypes from 'prop-types';
import { Box, Button, ListItem } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';

export const NavItem = (props) => {
    const { href, icon, title, ...others } = props;
    let location = useLocation();
    const active = href ? (location.pathname === href) : false;

    return (
        <ListItem
            disableGutters

            {...others}
        >
            <Button
                component={Link}
                to={{
                    pathname: href,
                }}
                startIcon={icon}
                sx={{
                    backgroundColor: active ? 'rgba(255,255,255, 0.04)' : 'transparent', // Reduzierte Opazität für aktiven Zustand
                    borderRadius: 1,
                    color: active ? '#E46450' : '#000000', // Hellerer Grauton für nicht aktiven Zustand
                    justifyContent: 'flex-start',
                    px: 2,
                    textAlign: 'left',
                    textTransform: 'none',
                    width: '100%',
                }}
            >
                <Box sx={{ flexGrow: 1 }}>
                    {title}
                </Box>
            </Button>
        </ListItem>
    );
};

NavItem.propTypes = {
    href: PropTypes.string,
    icon: PropTypes.node,
    title: PropTypes.string
};
