import React, { useState, useEffect } from 'react';
import { Box, Container, Stack, Typography, TextField, Button, Link, Alert } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { isPasswordValid } from '../misc/helperFunctions';

const PasswordResetPage = () => {
    const [token, setToken] = useState('');
    const [errors, setErrors] = useState({});
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordResetStatus, setPasswordResetStatus] = useState('pending');
    const [confirmedUser, setConfirmedUser] = useState(null); // Zustand für den gefundenen Benutzer
    const navigate = useNavigate();

    useEffect(() => {
        // Extrahiere den token Parameter aus der URL
        const urlSearchParams = new URLSearchParams(window.location.search);
        setToken(urlSearchParams.get('token'));

        console.log(token);

        // Überprüfe, ob der token Parameter vorhanden ist
        if (token) {
            fetch(`${process.env.REACT_APP_API_URI}/api/users/pwtoken/${token}`)
                .then(response => response.json())
                .then(data => {
                    if (data && data.user_id) {
                        setConfirmedUser(data); // Hier wird der gefundene Benutzer gespeichert
                    }
                })
                .catch(error => {
                    console.error('Fehler bei der API-Anfrage:', error);
                    setPasswordResetStatus('error');
                });
        } else {
            console.error('Kein Token in der URL gefunden.');
        }
    }, [token]);


    const handlePasswordReset = async () => {
        // Validate new password and confirmation
        const newErrors = {};
        if (!newPassword) {
            newErrors.newPassword = 'Neues Passwort erforderlich';
        }
        if (!isPasswordValid(newPassword)) {
            newErrors.newPassword = 'Passwort muss mindestens 6 Zeichen lang sein, einen Großbuchstaben und ein Sonderzeichen enthalten';
        }
        if (!confirmPassword) {
            newErrors.confirmPassword = 'Passwortbestätigung erforderlich';
        }
        if (newPassword !== confirmPassword) {
            newErrors.confirmPassword = 'Passwörter stimmen nicht überein';
        }
        setErrors(newErrors);

        if (Object.keys(newErrors).length === 0) {
            try {
                const response = await fetch(
                    `${process.env.REACT_APP_API_URI}/api/users/${confirmedUser.user_id}/password`,
                    {
                        method: 'PUT',
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: confirmedUser.api_key,
                        },
                        body: JSON.stringify({
                            currentPassword: token,
                            newPassword: newPassword,
                        }),
                    }
                );

                if (response.ok) {
                    console.log('Password updated successfully');
                    setPasswordResetStatus('success');
                    setTimeout(() => {
                        navigate('/login'); // Navigate after a short delay
                    }, 3000); // Delay of 3 seconds
                } else {
                    const errorData = await response.json();
                    throw new Error(errorData.message);
                }

            } catch (error) {
                console.error('Fehler bei der API-Anfrage:', error);
            }
        }
    };

    return (
        <Container maxWidth="sm" sx={{ mt: 1 }}>
            <Box
                mt={4}
                p={4}
                style={{
                    background: 'linear-gradient(to bottom, #FFFFFF, #F5F5F5)',
                    borderRadius: '10px',
                    border: '1px solid #F5F5F5',
                }}
            >
                <Typography variant="h5" component="h1" gutterBottom textAlign="center">
                    Passwort zurücksetzen
                </Typography>
                {passwordResetStatus === 'pending' ? (
                    <>
                        <Typography variant="body1" gutterBottom>
                            Bitte geben Sie Ihr neues Passwort ein und bestätigen Sie es.
                        </Typography>
                        {errors && Object.values(errors).join(', ') && (
                            <Alert severity="error" sx={{ my: 2 }}>
                                {Object.values(errors).join(', ')}
                            </Alert>
                        )}
                        <TextField
                            fullWidth
                            label="Neues Passwort"
                            type="password"
                            value={newPassword}
                            onChange={(event) => setNewPassword(event.target.value)}
                            margin="normal"
                            error={!!errors.newPassword}
                            helperText={errors.newPassword}
                        />
                        <TextField
                            fullWidth
                            label="Passwort bestätigen"
                            type="password"
                            value={confirmPassword}
                            onChange={(event) => setConfirmPassword(event.target.value)}
                            margin="normal"
                            error={!!errors.confirmPassword}
                            helperText={errors.confirmPassword}
                        />
                        <Button
                            variant="contained"
                            color="primary"
                            style={{ marginTop: '16px' }}
                            onClick={handlePasswordReset}
                        >
                            Passwort zurücksetzen
                        </Button>
                    </>
                ) : (
                    <Typography variant="body1" gutterBottom>
                        {passwordResetStatus === 'success'
                            ? "Ihr Passwort wurde erfolgreich zurückgesetzt. Sie können sich jetzt mit Ihrem neuen Passwort anmelden."
                            : "Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut."}
                    </Typography>
                )}
                <Stack direction="row" sx={{ mt: 4 }} alignItems="center" justifyContent="center">
                    <Link variant="body2" href="/login">Zurück zur Anmeldung</Link>
                </Stack>
            </Box>
        </Container>
    );
};

export default PasswordResetPage;
