// Function to renew the token
export async function renewToken() {
    try {
        const oldToken = localStorage.getItem('token');

        // Make a POST request to the token renewal API endpoint
        const response = await fetch(process.env.REACT_APP_API_URI + '/api/refresh-token', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ oldToken }),
        });

        // Check if the request was successful
        if (!response.ok) {
            throw new Error('Token renewal failed.');
        }

        // Extract the new token from the response
        const data = await response.json();
        const newToken = data.token;

        // Update the token in the local storage
        localStorage.setItem('token', newToken);

        // You may also update the user context with the new token if using context-based state management
        // userContext.setToken(newToken);

        console.log('Token renewed successfully.');


    } catch (error) {
        console.error(error);
    }
}

/**
 * Überprüft die Gültigkeit eines Passworts basierend auf bestimmten Kriterien.
 *
 * @param {string} password - Das zu überprüfende Passwort.
 * @returns {boolean} - Gibt true zurück, wenn das Passwort die Kriterien erfüllt, ansonsten false.
 */
export const isPasswordValid = (password) => {
    /**
     * Mindestlänge, die das Passwort haben muss.
     */
    const minLength = 6;

    /**
     * Überprüft, ob das Passwort einen Großbuchstaben enthält.
     */
    const hasUpperCase = /[A-Z]/.test(password);

    /**
     * Überprüft, ob das Passwort ein Sonderzeichen enthält.
     * Hier werden verschiedene Sonderzeichen durch die regulären Ausdrücke erkannt.
     */
    const hasSpecialChar = /[!@#$%^&*()_+\-={};':"|,.<>?]+/.test(password);

    /**
     * Gibt true zurück, wenn das Passwort die Kriterien erfüllt (Länge, Großbuchstabe, Sonderzeichen),
     * andernfalls wird false zurückgegeben.
     */
    return password.length >= minLength && hasUpperCase && hasSpecialChar;
};

